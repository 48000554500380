@charset "utf-8";
/* ================================================================================
共通修正
================================================================================ */
// ブレークポイント定義
$breakpoints: ("md": "screen and (max-width : 960px)",
  "tab": "screen and (min-width : 768px) and (max-width : 1024px)",
  "pc": "screen and (min-width : 768px)",
  "fixed": "screen and (min-width : 1366px)",
) !default;
$properties: ('pt': 'padding-top',
  'pl': 'padding-left',
  'pb': 'padding-bottom',
  'pr': 'padding-right',
  'mt': 'margin-top',
  'ml': 'margin-left',
  'mb': 'margin-bottom',
  'mr': 'margin-right',
  'wd': 'width',
  'he': 'height',
  'top': 'top',
  'left': 'left',
  'bottom': 'bottom',
  'right': 'right',
) !default;
// ブレークポイント処理
// 使用例：@include mq(md) {}
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
#footer {
  .foot_flex_box3 {
    .foot_chumon {
      justify-content: center;
    }
    .foot_icon {
      margin: 0 auto;
    }
    .footl{
      margin: 0 auto;
    }
  }
  .foot_flex_box2 {
    .foot_icon {
      margin: 0 auto;
    }
    .foot_time_box {
      display: flex;
      flex-wrap: wrap;
      dt, dd {
        padding-bottom: 15px;
      }
      dt {
        width: 35%;
      }
      dd {
        width: 65%;
      }
    }
  }
}
.columnIn{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  dt,dd{
    padding-bottom: 15px;
  }
  dt{
    padding-top: 7px;
    @include mq(md) {
      padding-top: 2px;
    }
  }
  dd{
    line-height: 1.9;
  }
}